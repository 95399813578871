import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';

const MajorDetail = ({ universities }) => {
    const { uniId, majorName } = useParams(); // Get the university ID and major name from URL
    const university = universities.find((univ) => univ.id === parseInt(uniId));

    const [activeTab, setActiveTab] = useState('general'); // Track active tab

    if (!university) return <div>University not found.</div>;

    const major = university.majors.find((m) => m.name === majorName);

    if (!major) return <div>Major not found.</div>;

    const hasCreditHours = major.creditHours !== undefined;
    const hasAccreditations = major.accreditations && major.accreditations.length > 0;
    const hasRequirements = major.GPARequirement || major.SATRequirement || major.otherRequirements;
    const hasFees = major.fees !== undefined;
    const hasElectives = major.electives && Object.keys(major.electives).length > 0;

    // Find other universities with the same major
    const similarUniversities = universities.filter((univ) =>
        univ.id !== university.id &&
        univ.majors.some((m) => m.name === majorName)
    );

    return (
        <div className="major-detail-page">
            {/* University Header */}
            <div className="university-header2">
                <img
                    src={university.imageUrl}
                    alt={`${university.name} logo`}
                    className="university-logo2"
                />
                <h1>{university.name || 'No information about university'} - {major.name || 'No information about major'}</h1>
            </div>

            {/* Tabs for Subjects, Credit Hours, General Info, and Electives */}
            <div className="major-box-search">
                <div className="search-tabs">
                    <button onClick={() => setActiveTab('general')}
                            className={`search-tab ${activeTab === 'general' ? 'active' : ''}`}>
                        General Info
                    </button>
                    <button onClick={() => setActiveTab('subjects')}
                            className={`search-tab ${activeTab === 'subjects' ? 'active' : ''}`}>
                        Subjects by Year
                    </button>
                    {hasCreditHours && (
                        <button onClick={() => setActiveTab('credits')}
                                className={`search-tab ${activeTab === 'credits' ? 'active' : ''}`}>
                            Credit Hours
                        </button>
                    )}
                    {hasElectives && (
                        <button onClick={() => setActiveTab('electives')}
                                className={`search-tab ${activeTab === 'electives' ? 'active' : ''}`}>
                            Electives
                        </button>
                    )}
                </div>
            </div>

            {/* Quick Description Box */}
            <div className="major-box">
                <h3>Description</h3>
                <p><strong>Quick Description:</strong> {major.description || 'No description available'}</p>
            </div>

            {/* Major Details in their own boxes */}
            <div className="major-detail-container">
                {activeTab === 'subjects' && (
                    <div className="major-box major-detail-full-row">
                        <h3>Course Subjects by Year</h3>
                        {major.years ? (
                            <table className="subjects-table">
                                <thead>
                                <tr>
                                    <th>Year</th>
                                    <th>Subjects</th>
                                </tr>
                                </thead>
                                <tbody>
                                {major.years.map((year, index) => (
                                    <tr key={index}>
                                        <td>Year {year.year || `Year ${index + 1}`}</td>
                                        <td>
                                            <ul>
                                                {year.subjects ? (
                                                    year.subjects.map((subject, subIndex) => (
                                                        <li key={subIndex}>{`${subject.code}: ${subject.title} (Prerequisite: ${subject.prerequisites || 'None'})`}</li>
                                                    ))
                                                ) : (
                                                    <li>No subjects available</li>
                                                )}
                                            </ul>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        ) : (
                            <p>No information available about subjects.</p>
                        )}
                    </div>
                )}

                {activeTab === 'credits' && hasCreditHours && (
                    <div className="major-box major-detail-full-row">
                        <h3>Required Credit Hours To Graduate:</h3>
                        <h1>{major.creditHours || 'No information about credit hours available'} Hours</h1>
                    </div>
                )}

                {/* General Information Box */}
                {activeTab === 'general' && (
                    <>
                        <div className="major-box">
                            <h3>General Information</h3>
                            <p><strong>Language of Instruction:</strong> {major.language || 'No information about language available'}</p>
                            <p><strong>Study Mode:</strong> {major.studyMode || 'No information about study mode available'}</p>
                            <p><strong>Area of Study:</strong> {major.areaOfStudy || 'Not Specified'}</p>
                            <p><strong>Degree:</strong> {major.degree || 'Not Specified'}</p>
                            <p><strong>Duration:</strong> {major.duration || 'Not Specified'}</p>
                        </div>

                        {/* Major-Specific Requirements Box */}
                        {hasRequirements && (
                            <div className="major-box">
                                <h3>Major-Specific Requirements</h3>
                                {major.GPARequirement && <p><strong>IGCSE Requirement:</strong> {major.GPARequirement}</p>}
                                {major.SATRequirement && <p><strong>SAT Requirement:</strong> {major.SATRequirement}</p>}
                                {major.otherRequirements && <p><strong>Other Requirements:</strong> {major.otherRequirements}</p>}
                            </div>
                        )}

                        {/* Accreditations Box */}
                        {hasAccreditations && (
                            <div className="major-box">
                                <h3>Accreditations</h3>
                                <ul>
                                    {major.accreditations.map((accreditation, index) => (
                                        <li key={index}>{accreditation}</li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {/* Fees Structure Box */}
                        {hasFees && (
                            <div className="major-box">
                                <h3>Fees Structure</h3>
                                <p><strong>Domestic Fees:</strong> {major.fees.domestic || 'No information about domestic fees available'}</p>
                                <p><strong>Foreign Fees:</strong> {major.fees.international || 'No information about international fees available'}</p>
                                {major.otherFees && (
                                    <div>
                                        <h4>Other Fees:</h4>
                                        <ul>
                                            {major.otherFees.map((fee, index) => (
                                                <li key={index}>{fee}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}

                {/* Electives Box */}
                {activeTab === 'electives' && hasElectives && (
                    <div className="major-box major-detail-full-row">
                        <h3>Electives</h3>
                        {Object.entries(major.electives).map(([category, electives]) => (
                            <div key={category}>
                                <h4>{category}</h4>
                                <ul>
                                    {electives.map((elective, index) => (
                                        <li key={index}>{elective}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <Link to={`/university/${uniId}`} className="back-link">Back to {university.name || 'University'}</Link>

            {/* Similar Universities with the Same Major */}
            {similarUniversities.length > 0 && (
                <div className="major-detail-full-row">
                    <div className="major-box-search">
                        <h3>Similar Universities Offering {majorName}</h3>
                        <div className="similar-universities-list">
                            {similarUniversities.map((similarUniv) => (
                                <Link key={similarUniv.id} to={`/university/${similarUniv.id}`}
                                      className="similar-university-card">
                                    <div className="similar-university-image-container">
                                        <img
                                            src={similarUniv.imageUrl || 'No image available'}
                                            alt={similarUniv.name || 'University Image'}
                                            className="similar-university-image"
                                        />
                                    </div>
                                    <div className="similar-university-details">
                                        <h4>{similarUniv.name}</h4>
                                        <p><strong>International:</strong> {similarUniv.isInternational ? 'Yes' : 'No'}</p>
                                        <p><strong>Dual Degree:</strong> {similarUniv.isDualDegree ? 'Yes' : 'No'}</p>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MajorDetail;
