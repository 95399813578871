import React, { useState } from 'react';

const UniversityRequirements = ({ university }) => {
    const [selectedSystem, setSelectedSystem] = useState('general');

    const handleSystemChange = (e) => {
        setSelectedSystem(e.target.value);
    };

    const renderRequirements = () => {
        switch (selectedSystem) {
            case 'thanawya':
                return (
                    <div>
                        <p><strong>Thanawya Requirement:</strong> {university.requirements?.thanawya?.Grade || 'No requirement available'}</p>
                       
                    </div>
                );
            case 'ig':
                return (
                    <div>
                        <p><strong>IG  Requirement:</strong> {university.requirements?.ig?.IGCSE || 'No requirement available'}</p>
                    </div>
                );
            case 'american':
                return (
                    <div>
                        <p><strong>American Requirement:</strong> {university.requirements?.american?.REQ || 'No SAT requirement available'}</p>
                    </div>
                );
            default:
                return (
                    <div>
                        <p><strong>General Requirement:</strong> {university.requirements?.General || 'No SAT requirement available'}</p>
                       
                    </div>
                );
        }
    };

    return (
        <div>
            <h3>Admission Requirements</h3>
            <label htmlFor="systemSelect">Select your education system:</label>
            <select className="search-dropdown" id="systemSelect" value={selectedSystem} onChange={handleSystemChange}>
                <option value="general">General</option>
                <option value="thanawya">Thanawya</option>
                <option value="ig">IG</option>
                <option value="american">American</option>
            </select>

            {/* Render the specific requirements based on the selected system */}
            {renderRequirements()}
        </div>
    );
};

export default UniversityRequirements;
