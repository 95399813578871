import React from 'react';
import { FaGlobe, FaChalkboardTeacher, FaMoneyBillAlt, FaDollarSign } from 'react-icons/fa';

const EducationPrograms = ({ majorDetails }) => {
  return (
    <div className="major-details">
      <div className="program-info-grid">
        {/* Language of Instruction */}
        <div className="program-info">
          <FaGlobe className="info-icon" />
          <div className="info-text">
            <strong>{majorDetails.language}</strong>
            <p>Language of instruction</p>
          </div>
        </div>

        {/* Study Mode */}
        <div className="program-info">
          <FaChalkboardTeacher className="info-icon" />
          <div className="info-text">
            <strong>{majorDetails.studyMode}</strong>
            <p>Study mode</p>
          </div>
        </div>

        {/* Domestic Fees */}
        <div className="program-info">
          <FaMoneyBillAlt className="info-icon" />
          <div className="info-text">
            <strong>{majorDetails.fees.domestic}</strong>
            <p>Fee for domestic students</p>
          </div>
        </div>

        {/* International Fees */}
        <div className="program-info">
          <FaDollarSign className="info-icon" />
          <div className="info-text">
            <strong>{majorDetails.fees.international}</strong>
            <p>Fee for Foreign students</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationPrograms;
