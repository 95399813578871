import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import UniversityList from './components/UniversityList';
import UniversityDetail from './components/UniversityDetail';
import Header from './components/Header';
import Footer from './components/Footer';
import NotFound from './components/NotFound';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './components/login';
import { setPersistence, browserLocalPersistence } from 'firebase/auth';
import { auth } from './firebase';
import About from './components/About';
import MajorDetail from './components/MajorDetail';
import { fetchUniversities } from './api';
import SpinnerImage from './assets/spinner.png';
import ScrollToTop from './components/ScrollToTop';
import ContactUs from "./components/ContactUs";

function App() {
  const [universities, setUniversities] = useState([]);
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        await setPersistence(auth, browserLocalPersistence);
        const unsubscribe = auth.onAuthStateChanged(user => {
          setCurrentUser(user);
          setAuthLoading(false);
        });
        return () => unsubscribe();
      } catch (error) {
        console.error('Error setting up Firebase auth:', error);
        setAuthLoading(false);
      }
    };

    initializeAuth();
  }, []);

  useEffect(() => {
    const loadUniversities = async () => {
      try {
        const universitiesData = await fetchUniversities();
        setUniversities(universitiesData);
      } catch (error) {
        console.error('Error fetching universities:', error);
        setError('Failed to load universities.');
      }
    };

    loadUniversities();
  }, []);

  useEffect(() => {
    if (!authLoading) {
      setTimeout(() => {
        setFadeOut(true);
      }, 100);

      setTimeout(() => setShowSpinner(false), 900);
    }
  }, [authLoading]);

  if (showSpinner) {
    return (
      <div className={`loading-spinner ${fadeOut ? 'fade-out' : ''}`}>
        <img src={SpinnerImage} alt="Loading..." className="spinner-image" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Router>
      <AppContent universities={universities} currentUser={currentUser} />
    </Router>
  );
}

function AppContent({ universities, currentUser }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [fadeClass, setFadeClass] = useState('page-transition');
  const hideHeaderAndFooter = location.pathname === '/login';

  useEffect(() => {
    setFadeClass('page-transition-exit');  // Start fade-out
    const timeoutId = setTimeout(() => {
      setFadeClass('page-transition');  // Trigger fade-in
    }, 200);  // Match the transition time in CSS

    return () => clearTimeout(timeoutId);  // Cleanup timeout on unmount
  }, [location]);

  useEffect(() => {
    if (!currentUser && location.pathname !== '/login') {
      navigate('/login');
    } else if (currentUser && location.pathname === '/login') {
      navigate('/');
    }
  }, [currentUser, navigate, location.pathname]);

  return (
    <div className={fadeClass}>
      {!hideHeaderAndFooter && <Header />}
      <ScrollToTop />  {/* Add ScrollToTop here */}
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <UniversityList universities={universities} />
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route
          path="/university/:id"
          element={
            <ProtectedRoute>
              <UniversityDetail universities={universities} />
            </ProtectedRoute>
          }
        />
        <Route path="/university/:uniId/majors/:majorName" element={<ProtectedRoute><MajorDetail universities={universities} /></ProtectedRoute>} />
        <Route path="/about" element={<ProtectedRoute><About /></ProtectedRoute>} />
        <Route path="/contactus" element={<ProtectedRoute><ContactUs /></ProtectedRoute>} />
        <Route path="*" element={<NotFound />} />
      </Routes>

      {!hideHeaderAndFooter && (
        <div className="footer-color">
          <Footer />
        </div>
      )}
    </div>
  );
}

export default App;
