import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import EducationPrograms from './EducationPrograms'; // Import the EducationPrograms component
import '../App.css';
import UniversityRequirements from './UniversityRequirements'; // Assuming this file will have the new styles

const UniversityDetail = ({ universities }) => {
  const { id } = useParams(); // Get the university ID from the URL
  const university = universities.find((univ) => univ.id === parseInt(id));
  const hasAccreditations = university.accreditations && university.accreditations.length > 0;
  const hasPartner = university.PartnerUniversitys && university.PartnerUniversitys.length > 0;
  const hasAchievements = university.achievements && university.achievements.length > 0;
  const [expandedMajors, setExpandedMajors] = useState([]); // State to track expanded majors

  if (!university) return <div className="error-message">University not found.</div>;

  // Function to toggle major expansion
  const toggleExpand = (majorName) => {
    setExpandedMajors((prevExpanded) =>
        prevExpanded.includes(majorName)
            ? prevExpanded.filter((name) => name !== majorName)
            : [...prevExpanded, majorName]
    );
  };

  // Function to determine color based on ranking
  const getRankingColor = (rank) => {
    if (rank <= 250) return 'green'; // Best ranks
    if (rank <= 300) return 'orange'; // Mid-range ranks
    return 'red'; // Lower ranks
  };

  return (
      <div className="university-detail-page2">
        {/* University Header */}
        <div className="university-header2">
          <img
              src={university.imageUrl}
              alt={`${university.name} logo`}
              className="university-logo2"
          />
          <h2 className="university-name2">{university.name || 'University Name Unavailable'}</h2>
          <p className="university-fees2">~ EGP {university.fees || 'Fees Unavailable'} / year</p>
        </div>

        {/* Main Content in a 3-column grid */}
        <div className="university-detail-container">
          {/* Ranking */}
          <div className="university-box">
            <h3>International Ranking</h3>
            <div className="rankings-box">
              <div className="ranking-item">
                <h3>Times Higher Education</h3>
                <span className="ranking-number" style={{ color: getRankingColor(university.ranking?.THE) }}>
                {university.ranking?.THE || 'No THE Ranking available'}
              </span>
              </div>
            </div>
          </div>

          {/* About the University */}
          <div className="university-box">
            <h3>About the University</h3>
            <p>{university.description || 'No information about the university available'}</p>
          </div>

          {/* University Details */}
          <div className="university-box">
            <h3>University Details</h3>
            <ul>
              <li><strong>Partnered with International University:</strong> {university.isDualDegree ? 'Yes' : 'No'}</li>
              <li><strong>International:</strong> {university.isInternational ? 'Yes' : 'No'}</li>
              <li><strong>Accommodation Available:</strong> {university.accommodationAvailable ? 'Yes' : 'No'}</li>
              <li><strong>Sports Available:</strong> {university.sportsAvailable ? 'Yes' : 'No'}</li>
              <li><strong>Transportation Available:</strong> {university.transportationAvailable ? 'Yes' : 'No'}</li>
            </ul>
          </div>

          {/* Accreditations */}
          <div className="university-box">
            <h3>Accreditations</h3>
            {hasAccreditations ? (
                <ul>
                  {university.accreditations.map((accreditation, index) => (
                      <li key={index}>{accreditation}</li>
                  ))}
                </ul>
            ) : (
                <p>No accreditations available.</p>
            )}
          </div>
          {/* Partner */}
          <div className="university-box">
            <h3>Partner International Universities</h3>
            {hasPartner ? (
                <ul>
                  {university.PartnerUniversitys.map((PartnerUniversitys, index) => (
                      <li key={index}>{PartnerUniversitys}</li>
                  ))}
                </ul>
            ) : (
                <p>No Partner Universities available.</p>
            )}
          </div>

          {/* Achievements */}
          <div className="university-box">
            <h3>Achievements</h3>
            {hasAchievements ? (
                <ul>
                  {university.achievements.map((achievement, index) => (
                      <li key={index}>{achievement}</li>
                  ))}
                </ul>
            ) : (
                <p>No achievements available.</p>
            )}
          </div>

          {/* Sports */}
          <div className="university-box">
            <h3>Available Sports</h3>
            <ul>
              {university.sports?.length > 0 ? (
                  university.sports.map((sport, index) => <li key={index}>{sport}</li>)
              ) : (
                  <p>No sports available</p>
              )}
            </ul>
          </div>
          {/* Facilities */}
          <div className="university-box">
            <h3>Facilities Available</h3>
            <ul>
              {university.facilities?.length > 0 ? (
                  university.facilities.map((facility, index) => <li key={index}>{facility}</li>)
              ) : (
                  <p>No facilities information available</p>
              )}
            </ul>
          </div>
          {/* Accommodation Information */}
          {university.accommodationAvailable && (
              <div className="university-box">
                <h3>Accommodation Information</h3>
                <p>{university.accommodationInfo?.description || 'No specific details provided about accommodation.'}</p>
                {university.accommodationInfo && (
                    <table className="accommodation-info-table">
                      <thead>
                      <tr>
                        <th>Room Type</th>
                        <th>Price (EGP)</th>
                      </tr>
                      </thead>
                      <tbody>
                      {university.accommodationInfo.roomTypes.map((roomType, index) => (
                          <tr key={index}>
                            <td>{roomType}</td>
                            <td>{university.accommodationInfo.pricing[index]}</td>
                          </tr>
                      ))}
                      </tbody>
                    </table>
                )}
              </div>
          )}

          {/* Testimony */}
          <div className="university-box">
            <h3>Student Testimony</h3>
            <p>"{university.testimony || 'No student testimony available'}"</p>
          </div>
        </div>

        {/* University Requirements (in its own row) */}
        <div className="university-detail-full-row">
          <div className="university-box">
            <UniversityRequirements university={university} />
          </div>
        </div>

        {/* Educational Programs */}
        <div className="university-detail-full-row">
          <div className="university-box">
            <h3>Education Programs</h3>
            {university.majors.map((major, index) => (
                <div key={index} className="major-section">
                  <div className="major-header">
                    <h4>{major.name || 'No information about major name'}</h4>
                    <button onClick={() => toggleExpand(major.name)} className="expand-button">
                      {expandedMajors.includes(major.name) ? 'Collapse' : 'Expand'}
                    </button>
                  </div>

                  {expandedMajors.includes(major.name) && (
                      <div>
                        <EducationPrograms majorDetails={major} />
                        <Link to={`/university/${university.id}/majors/${major.name}`} className="apply-button">
                          Learn More
                        </Link>
                      </div>
                  )}
                </div>
            ))}
          </div>
        </div>
      </div>
  );
};

export default UniversityDetail;
