import React from 'react';
import logo from '../assets/logo.png'; // Replace with your logo's path
import '../App.css'; // Assuming you're using the same CSS file for styling

const About = () => {
    return (
        <div className="about-page">
            {/* Logo at the top */}
            <img src={logo} alt="EDU Finders Logo" className="about-logo" />

            <h1>About EDU Finders</h1>
            <p>
                EDU Finders is a platform dedicated to guiding students towards their ideal universities and programs. We provide accurate, updated information on university rankings, course offerings, fees, and much more, ensuring that students make informed decisions about their future.
            </p>
            <p>
                Whether you are exploring international universities or searching for the right local programs, EDU Finders is your go-to resource for higher education opportunities.
            </p>

            <h2>Our Mission</h2>
            <p>
                Our mission is to break down barriers to education by offering a comprehensive and user-friendly platform for students. We aim to connect students to universities across Egypt, ensuring that they find institutions that fit both their academic ambitions and financial needs. Empowering students is at the heart of everything we do.
            </p>

            <h2>Our Vision</h2>
            <p>
                At EDU Finders, we envision a world where every student, regardless of background, can access quality education. By providing transparent information and personalized resources, we help students build pathways to success in higher education and beyond.
            </p>

            <h2>Contact Us</h2>
            <p>
                If you have any questions, feedback, or partnership inquiries, we'd love to hear from you. Reach out to us at{' '}
                <a href="mailto:hi@edufinders.com">hi@edufinders.com</a>, and our team will get back to you promptly.
            </p>
        </div>
    );
};

export default About;
