// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';  // Import Firebase Auth

// Firebase configuration (ensure this is kept secure and private)
const firebaseConfig = {
  apiKey: "AIzaSyC3DUsGbh1u9jlLHAvtpaNtuByGv0gmDxs",
  authDomain: "eduapp-98f9c.firebaseapp.com",
  projectId: "eduapp-98f9c",
  storageBucket: "eduapp-98f9c.appspot.com",
  messagingSenderId: "850587008968",
  appId: "1:850587008968:web:59bb93fc45d33841680afd",
  measurementId: "G-925HBFDVZS"
};

// Initialize Firebase App
const app = initializeApp(firebaseConfig);

// Initialize Firestore Database
const db = getFirestore(app);

// Initialize Firebase Authentication
const auth = getAuth(app);

// Set persistence for authentication to local (will persist across browser sessions)
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log('Auth persistence set to local');
  })
  .catch((error) => {
    console.error('Error setting auth persistence:', error);
  });

export { db, auth };
