// src/api.js
export const fetchUniversities = async () => {
  try {
    const context = require.context('./data/universities', true, /\.json$/);
    const universities = context.keys().map(key => context(key)); // Load all university JSON files dynamically
    return universities;
  } catch (error) {
    console.error('Error fetching universities from JSON:', error);
    throw error;
  }
};
