import '../App.css'; // Reuse the same CSS

const ContactUs = () => {


    return (
        <div className="about-page">
            <h1>Contact Us</h1>
            <p>
                We would love to hear from you! Whether you have questions, suggestions, or just want to say hi, feel
                free to reach out to us through the form below.
            </p>
            <p className="contact-info">
                You can email us directly at{' '}
                <a href="mailto:hi@edufinders.com">hi@edufinders.com</a>.
            </p>
            <p className="contact-info">
                You can also contact us directly on WhatsApp at{' '}
                <a href="https://wa.me/201070219376">+20 107 021 9376</a>.
            </p>

        </div>
    );
};

export default ContactUs;
