import React from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from '../firebase'; // Ensure the path to Firebase Auth is correct

const ProtectedRoute = ({ children }) => {
  const user = auth.currentUser;

  if (!user) {
    return <Navigate to="/login" />; // Redirect to login if not authenticated
  }

  return children; // Render the protected component if authenticated
};

export default ProtectedRoute;
