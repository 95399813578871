import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import '../App.css';
import logo from '../assets/logo.png';
import { fetchUniversities } from '../api';
import TrendingMajors from "./TrendingMajors";

const UniversityList = () => {
  const [universities, setUniversities] = useState([]);
  const [search, setSearch] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]); // State to handle suggestions
  const [sortOption, setSortOption] = useState('name');
  const [dualDegreeFilter, setDualDegreeFilter] = useState(false);
  const [internationalFilter, setInternationalFilter] = useState(false);
  const [majorSearch, setMajorSearch] = useState('');
  const [activeTab, setActiveTab] = useState('university');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadUniversities = async () => {
      setLoading(true);
      try {
        const universityData = await fetchUniversities();
        setUniversities(universityData);
      } catch (err) {
        setError('Failed to load universities.');
      } finally {
        setLoading(false);
      }
    };

    loadUniversities();
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearch(value);

    if (value) {
      const filtered = universities.filter((university) =>
        university.name?.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredSuggestions(filtered);
    } else {
      setFilteredSuggestions([]);
    }
  };

  const handleMajorSearchChange = (e) => {
    setMajorSearch(e.target.value);
  };

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
    setSearch('');
    setMajorSearch('');
  };

  const sortedUniversities = [...universities].sort((a, b) => {
    if (sortOption === 'name') {
      return a.name?.localeCompare(b.name);
    } else if (sortOption === 'ranking') {
      const rankingA = a.ranking?.QS ?? Infinity;
      const rankingB = b.ranking?.QS ?? Infinity;
      return rankingA - rankingB;
    } else if (sortOption === 'fees-low-to-high') {
      const feesA = parseFloat(a.fees) || Infinity;
      const feesB = parseFloat(b.fees) || Infinity;
      return feesA - feesB;
    }
    return 0;
  });

  const filteredUniversities = sortedUniversities.filter((university) => {
    const matchesSearch = university.name?.toLowerCase().includes(search.toLowerCase());
    const matchesDualDegree = dualDegreeFilter ? university.isDualDegree === true : true;
    const matchesInternational = internationalFilter ? university.isInternational === true : true;
    const matchesMajor = majorSearch
      ? university.majors?.some((major) =>
          major.name.toLowerCase().includes(majorSearch.toLowerCase())
        )
      : true;

    const matchesFacilities = activeTab === 'facilities'
      ? university.facilities?.some((facility) =>
          facility.toLowerCase().includes(search.toLowerCase())
        )
      : true;

    const matchesSports = activeTab === 'sports'
      ? university.sports?.some((sport) => sport.toLowerCase().includes(search.toLowerCase()))
      : true;

    if (activeTab === 'university') {
      return matchesSearch && matchesDualDegree && matchesInternational;
    } else if (activeTab === 'majors') {
      return matchesMajor;
    } else if (activeTab === 'facilities') {
      return matchesFacilities;
    } else if (activeTab === 'sports') {
      return matchesSports;
    }

    return false;
  });

  if (loading) {
    return <div className="loading-screen">Loading universities...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div>
      <div className="landing-page-container">
        <div className="top-section">
        <img src={logo} alt="University Portal Landing" className="logo-image" />
          <div className="headline">
            <h1>University Search, Made Easy.</h1>
            <p>Find the best universities in Egypt with ease.</p>
          </div>
         {/*<button className="cta-button">TRY IT NOW! </button>
          <p className="no-obligation"> </p> */}

        </div>
      </div>

      <div className="search-overlay">
        <div className="search-tabs">
          <button
            className={`search-tab ${activeTab === 'university' ? 'active' : ''}`}
            onClick={() => handleTabChange('university')}
          >
            University
          </button>
          <button
            className={`search-tab ${activeTab === 'majors' ? 'active' : ''}`}
            onClick={() => handleTabChange('majors')}
          >
            Majors
          </button>
          <button
            className={`search-tab ${activeTab === 'facilities' ? 'active' : ''}`}
            onClick={() => handleTabChange('facilities')}
          >
            Facilities
          </button>
          <button
            className={`search-tab ${activeTab === 'sports' ? 'active' : ''}`}
            onClick={() => handleTabChange('sports')}
          >
            Sports
          </button>
        </div>

        <div className="search-container">
          {(activeTab === 'university' || activeTab === 'facilities' || activeTab === 'sports') && (
            <div className="search-box">
              <FaSearch className="search-icon" />
              <input
                type="text"
                placeholder={
                  activeTab === 'university'
                    ? 'Search for a university...'
                    : activeTab === 'facilities'
                    ? 'Search for facilities...'
                    : 'Search for sports...'
                }
                value={search}
                onChange={handleInputChange}
                className="search-input"
              />
            </div>
          )}
          {activeTab === 'majors' && (
            <div className="search-box">
              <FaSearch className="search-icon" />
              <input
                type="text"
                placeholder="Search by major..."
                value={majorSearch}
                onChange={handleMajorSearchChange}
                className="search-input"
              />
            </div>
          )}

          <select
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
            className="search-dropdown"
          >
            <option value="name">Sort by Name</option>
            <option value="ranking">Sort by QS Ranking</option>
            <option value="fees-low-to-high">Sort by Fees</option>
          </select>

          {/*<button className="search-button">Search</button>*/}
        </div>

        <div className="filters">
          <label>
            <input
              type="checkbox"
              checked={dualDegreeFilter}
              onChange={(e) => setDualDegreeFilter(e.target.checked)}
            />
            Dual Degree
          </label>
          <label>
            <input
              type="checkbox"
              checked={internationalFilter}
              onChange={(e) => setInternationalFilter(e.target.checked)}
            />
            International Universities
          </label>
        </div>
      </div>
      <TrendingMajors universities={universities} /> {/* Call TrendingMajors component */}


      <div className="university-list">
        {filteredUniversities.length > 0 ? (
            filteredUniversities.map((university) => (
                <Link to={`/university/${university.id}`} className="university-card-link" key={university.id}>
                  <div className="university-card">
                    <div className="admission-status-container">
                      <div
                          className={`admission-light ${
                              university.admissionsOpen ? 'green-light' : 'red-light'
                          }`}
                          data-tooltip={university.admissionsOpen ? 'Admission Open' : 'Admission Closed'}
                      ></div>
                    </div>
                    <div className="university-image-container">
                      <img
                          src={university.imageUrl || 'No image available'}
                          alt={university.name || 'University Image'}
                          className="university-image"
                      />
                    </div>

                    <div className="university-details">
                      <h3>{university.name || 'No university name available'}</h3>
                      <p>
                        <strong>Partnered with International
                          University:</strong> {university.isDualDegree ? 'Yes' : 'No'}
                      </p>
                      <p>
                        <strong>International:</strong> {university.isInternational ? 'Yes' : 'No'}
                      </p>
                    </div>

                    <div className="university-actions">
                      <p className="fees">
                        ~ EGP {university.fees || 'No fee information available'} / year
                      </p>
                    </div>
                  </div>
                </Link>
            ))
        ) : (
            <p>No universities match your search. Please try again.</p>
        )}
      </div>
    </div>
  );
};

export default UniversityList;
