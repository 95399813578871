import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const TrendingMajors = ({ universities }) => {
    const trendingMajors = [];

    universities.forEach((university) => {
        university.majors.forEach((major) => {
            if (major.isTrending) {
                trendingMajors.push({
                    universityName: university.name,
                    universityLogo: university.imageUrl,
                    majorName: major.name,
                    description: major.description,
                    id: `${university.id}/majors/${major.name}`
                });
            }
        });
    });

    return (
        <div className="trending-majors-section">
            <h2>Trending Majors</h2>
            <div className="trending-majors-list">
                {trendingMajors.map((item) => (
                    <Link to={`/university/${item.id}`} className="trending-major-card" key={item.id}>
                        <div className="university-info">
                            <img src={item.universityLogo} alt={item.universityName} className="university-logo" />
                            <h3>{item.universityName}</h3>
                        </div>
                        <h4>{item.majorName}</h4>
                        <p>{item.description || 'No description available'}</p>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default TrendingMajors;
