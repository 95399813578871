import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase'; // Import Firebase Auth
import '../App.css'; // Assuming your styles are stored here
import logo from '../assets/logo.png'; // Replace with your logo path

const Header = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  // Listen for authentication state changes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user); // Set the logged-in user
    });

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut(); // Log the user out
      navigate('/login'); // Redirect to the login page after logout
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  return (
    <header className="custom-header">
  <div className="header-content">
    {/* Logo (Clickable to Home) */}
    <Link to="/" className="logo-link">
      <img src={logo} alt="Logo" className="header-logo" />
    </Link>

    {/* Navigation Links */}
    <nav className="nav-bar">
      <Link to="/About" className="nav-link">About</Link>
      <Link to="/contactus" className="nav-link">Contact Us</Link>
      
    </nav>

    {/* Right Side Buttons */}
    <div className="user-info">
      {user ? (
        <>
          <div className="user-menu">
            <button className="greeting-dropdown">
              Hi, {user.email.split('@')[0].charAt(0).toUpperCase() + user.email.split('@')[0].slice(1)}
              <span className="arrow-icon">▼</span>
            </button>
            <div className="dropdown-content">
              <button onClick={handleLogout} className="logout-button">Sign Out</button>
            </div>
          </div>

        </>
      ) : (
        <>
          <Link to="/login" className="login-button">Sign In</Link>
          <Link to="/signup" className="signup-button">Try Free</Link>
        </>
      )}
    </div>
  </div>
</header>

  );
};

export default Header;
